<template>
  <div>
    <!-- 统计单月交易量、交易额 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>单月交易统计</h3>
        </el-col>        
    </el-row>

    <el-row style="margin-bottom:20px;">
        <el-col :span="24" class="title">
            月份：<el-date-picker
                    v-model="volumeDay"
                    :editable="false"
                    :clearable="false"
                    type="month"
                    size="default"
                    @change="GetDataInfo"
                />
        </el-col>
    </el-row>


    <el-row :gutter="10">

      <el-col :span="6">
        <el-card shadow="always" class="cardBill">
          <span class="blueSize">{{info.totalNum}}</span><span class="blueSize">单</span> 
          <el-divider direction="vertical" />
          <span class="redSize">{{info.totalPrice}}</span><span class="redSize">元</span> 
          <div class="billTitle">
            总销售
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card shadow="always" class="cardBill">
          <span class="blueSize">{{info.purchaseNum}}</span><span class="blueSize">单</span> 
          <el-divider direction="vertical" />
          <span class="redSize">{{info.purchasePrice}}</span><span class="redSize">元</span> 
          <div class="billTitle">
            采购单
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card shadow="always" class="cardBill">
          <span class="blueSize">{{info.saleNum}}</span><span class="blueSize">单</span> 
          <el-divider direction="vertical" />
          <span class="redSize">{{info.salePrice}}</span><span class="redSize">元</span> 
          <div class="billTitle">
            销售单
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card shadow="always" class="cardBill">
          <span class="blueSize">{{info.retailNum}}</span><span class="blueSize">单</span> 
          <el-divider direction="vertical" />
          <span class="redSize">{{info.retailPrice}}</span><span class="redSize">元</span> 
          <div class="billTitle">
            零售单
          </div>
        </el-card>
      </el-col>

      
    </el-row>

    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:100%;height:500px;" ></div>
    <div class="dataSource">
        数据来源：采购单、销售单、零售单
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment';
export default {
    data(){
        return{
            info:{
                retailNum:0,   //零售总交易量
                retailPrice:0,  //零售总交易额
                hourList:['1日','2日','3日','4日','5日','6日','7日','8日','9日','10日','11日','12日','13日','14日','15日','16日','17日','18日','19日','20日','21日','22日','23日','24日','25日','26日','27日','28日','29日','30日','31日'],  //小时列表
                retailBillNumList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],   //零售交易量列表
                retailTotalPriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //零售交易额列表

                saleNum:0,  //销售单总交易量
                salePrice:0,
                saleBillNumList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],   //销售交易量列表
                saleTotalPriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //销售交易额列表

                purchaseBillNumList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //采购交易量列表
                purchaseTotalPriceList:[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],  //采购交易额列表
            },
            volumeDay:moment(new Date()).format("YYYY-MM"),
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['零售额','销售额','采购额','零售量','销售量','采购量'] //示例图
              },
              xAxis: {
                type: 'category',
                data: this.info.hourList,
              },
              yAxis: [
                  {
                      type: 'value',
                      name:'交易额',
                      position: 'left',
                      axisLabel: {
                          formatter: '{value} 元'
                      }
                  },
                  {
                      type:'value',
                      name:'交易量',
                      position: 'right',
                      axisLabel: {
                          formatter: '{value} 单'
                      }
                  },
              ],
              series: [
                
                {
                  name:'零售额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.retailTotalPriceList,
                  type: 'line',  //线状图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#33ccff'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                  connectNulls:true,  //连上断开的点
                },
                {
                  name:'销售额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.saleTotalPriceList,
                  type: 'line',  //线状图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#DC143C'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                  connectNulls:true,  //连上断开的点
                },
                {
                  name:'采购额',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.purchaseTotalPriceList,
                  type: 'line',  //线状图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#9F5F9F'
                  },  //颜色
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 元'
                    }
                  },
                  connectNulls:true,  //连上断开的点
                },

                {
                  name:'零售量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.retailBillNumList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 单'
                    }
                  },
                },
                {
                  name:'销售量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.saleBillNumList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 单'
                    }
                  },
                },
                {
                  name:'采购量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.purchaseBillNumList,
                  type: 'bar',
                  itemStyle:{
                    color:'#9F5F9F'
                  },
                  tooltip:{
                    valueFormatter:function(value){
                      return value + ' 单'
                    }
                  },
                  
                },
              
              ]
            };
            myChart.setOption(option);
        },

        //获取数据
        async GetDataInfo(){
            let param = {
              volumeDay:moment(this.volumeDay).format("YYYY-MM"),
            }
            await this.$api.stat.StatVolumeMonthInfo(param).then(res=>{
                if(res.data.status === 200){
                    this.info = res.data.data;
                    this.myEcharts();
                }
            })
        },
    },
    mounted(){
        this.GetDataInfo();
    },
}
</script>

<style scoped>
.title{text-align: center;}
.cardBill{text-align: center;}
.cardBill .blueSize{color: #3673e6;}
.cardBill .redSize{color: #e72552;}
.cardBill .billTitle {padding-top: 15px;}
.dataSource{margin-left: 120px;font-size: 14px;color: #ccc;}
</style>